class ZIDXAccountListingEdit implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountListingEditContainer";
    }
    getMatchURL(){
        return "/account/listings/edit";
    }
    render(){
        // console.log("listing edit");
    }
}